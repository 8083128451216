import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  Col, Row,
} from 'antd';
import Image from 'src/components/Image';
import PageTitle from 'src/components/PageTitle';
import DownloadCv from './DownloadCv';
import RichText from '../RichText';

const AboutDesktop = function ({
  bioDownload,
  paragraphCareer,
  paragraphCooperation,
  paragraphEducation,
  photosCareer,
  photosEdu,
  title,
}) {
  const images = useMemo(() => [...photosEdu, ...photosCareer], [photosCareer, photosEdu]);

  return (

    <Row
      gutter={[36, 36]}
      className='about-desktop'
    >
      <Col
        span={12}
      >
        <div className='about-desktop__column'>
          <PageTitle title={title} />
          <div>
            <RichText text={paragraphEducation} />
          </div>
          <Image
            src={get(images, '[2].url')}
            {...get(images, '[2]')}
            preview={false}
          />
          <div>
            <RichText text={paragraphCooperation} />
            <DownloadCv bioDownload={bioDownload} />
          </div>
        </div>
      </Col>
      <Col
        span={12}
      >
        <div className='about-desktop__column'>
          <div style={{
            marginBottom: '1em',
          }}
          >
            <Image
              src={get(images, '[0].url')}
              {...get(images, '[0]')}
              preview={false}
            />
          </div>
          <RichText text={paragraphCareer} />
          <Image
            src={get(images, '[1].url')}
            {...get(images, '[1]')}
            preview={false}
          />
        </div>
      </Col>
    </Row>
  );
};

AboutDesktop.propTypes = {
  bioDownload: PropTypes.array.isRequired,
  paragraphCareer: PropTypes.object.isRequired,
  paragraphCooperation: PropTypes.object.isRequired,
  paragraphEducation: PropTypes.object.isRequired,
  photosCareer: PropTypes.object.isRequired,
  photosEdu: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default AboutDesktop;
