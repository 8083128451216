import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import slice from 'lodash/slice';
import { Grid } from 'antd';
import PageLayout from 'src/components/Layout';
import useContentful from 'src/helpers/hooks/useContentful';
import AboutDesktop from '../components/about/AboutDesktop';
import AboutMobile from '../components/about/AboutMobile';

const { useBreakpoint } = Grid;

export const query = `
{
  bioPage(id: "4kMXvXbTCIyXr0YgTke8fE") {
    pageHeader {
      pageTitle
      pageSubtitle
      pagePhotoCollection {
        items {
          fileName
          url
          width
          height
        }
      }
    }
    paragraphEducation {
     json 
    }
    paragraphCareer {
     json 
    }
    paragraphCooperation {
     json 
    }
    bioPagePhotosCollection {
      items {
        fileName
        url
        width
        height
      }
    }
    bioDownloadCollection {
      items {
        url
        title
      }
    }
  }
}
`;

const AboutIndex = function ({ location }) {
  const { lg } = useBreakpoint();

  const { data, loading } = useContentful(query);
  const title = get(data, 'bioPage.pageHeader.pageTitle');
  const mainPhoto = get(data, 'bioPage.pageHeader.pagePhotoCollection.items');

  const paragraphEducation = get(data, 'bioPage.paragraphEducation.json');
  const paragraphCareer = get(data, 'bioPage.paragraphCareer.json');
  const paragraphCooperation = get(data, 'bioPage.paragraphCooperation.json');
  const bioDownload = get(data, 'bioPage.bioDownloadCollection.items');

  const bioPagePhotos = get(data, 'bioPage.bioPagePhotosCollection.items');
  const photosEdu = slice(bioPagePhotos, 0, 2);
  const photosCareer = slice(bioPagePhotos, 2);

  const renderResponsiveLayout = useMemo(() => (
    !lg ? (
      <AboutMobile
        paragraphCareer={paragraphCareer}
        paragraphCooperation={paragraphCooperation}
        paragraphEducation={paragraphEducation}
        photosCareer={photosCareer}
        photosEdu={photosEdu}
        bioDownload={bioDownload}
      />
    ) : (
      <AboutDesktop
        paragraphCareer={paragraphCareer}
        paragraphCooperation={paragraphCooperation}
        paragraphEducation={paragraphEducation}
        photosCareer={photosCareer}
        photosEdu={photosEdu}
        bioDownload={bioDownload}
        title={title}
      />
    )
  ), [
    bioDownload, lg, paragraphCareer, paragraphCooperation,
    paragraphEducation, photosCareer, photosEdu, title]);

  return (
    <PageLayout
      location={location}
      title={title}
      photo={mainPhoto}
      loading={loading}
    >
      {renderResponsiveLayout}
    </PageLayout>
  );
};

AboutIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default AboutIndex;
