import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import words from 'lodash/words';
import { Button } from 'antd';

const DownloadCv = function ({ bioDownload }) {
  const getBioCountry = (title) => {
    const titleWords = words(title);
    return titleWords[titleWords.length - 1];
  };

  return (
    <div className='download-cv-container'>
      {map(bioDownload, (bioDoc) => (
        <div
          className='custom-button'
          key={bioDoc.url}
        >
          <Button
            href={bioDoc.url}
            target='_blank'
          >
            DOWNLOAD BIO
            {' '}
            {getBioCountry(bioDoc.title)}
          </Button>
        </div>
      ))}
    </div>
  );
};

DownloadCv.propTypes = {
  bioDownload: PropTypes.array.isRequired,
};

export default DownloadCv;
